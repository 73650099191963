import { useLocation, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Logout } from '../Logout'
import { useAuth } from './Auth'
import {AuthModel} from './_models'
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}


export function setupAxios(axios: any) {
  

  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth ) {
        config.headers.Authorization = `Bearer ${auth?.data?.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  // axios.interceptors.response.use(
  //   (response:any) => {
  //     return response;
  //   },
  //   (error:any) => {
  //     if (error.response && error.response.status === 401) {
  //       // If the user is not on the login page, redirect to the login page
  //       const history = useNavigate();
  //       const loc = useLocation();

  //       if (loc.pathname!== '/auth/login') {
  //         history('/auth/login');
  //         localStorage.removeItem('kt-auth-react-v');
  //         localStorage.removeItem('products');
  //       }
  //     }
  //     return Promise.reject(error);
  //   }
  // );
  
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
