import axios from "axios";
import { useContext } from "react";
// import moment from "moment";
// import { toast } from "react-toastify";
import { string } from "yup";


// const API_ROUTE = process.env.REACT_APP_API_URL;
const API_ROUTE = 'https://api.ecommerce-b2b.fcpo.agency/api';


interface IApiRequest {
    route: string;
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "IGET";
    requiresAuth?: boolean;
    body?: any;
    headers?: any;
    formData?: boolean;
    params?: {
        startIndex?: number,
        stopIndex?: number,
    }
}


export const ApiRequest = async ({
    route,
    method,
    requiresAuth = false,
    body,
    formData,
    params = {
        startIndex: 1,
        stopIndex: 1,
    }
}: IApiRequest) => {
    //  try {
    //     const headers: any = requiresAuth
    //       ? {
    //           Authorization: `Bearer ${getLocalUser().token}`,
    //         }
    //       : null;
    //     let response: any;

    //     const r = `${API_ROUTE}/${route}`;
    //     console.log(r);

    //     if (method === "POST") {
    //       response = await axios.post(r, body, {
    //         headers,
    //       });
    //     } else if (method === "GET") {
    //       response = await axios.get(r, {
    //         headers,
    //       });
    //     } else if (method === "DELETE") {
    //       response = await axios.delete(r, {
    //         headers,
    //       });
    //     } else if (method === "PUT") {
    //       response = await axios.put(r, body, {
    //         headers,
    //       });
    //     }

    //     return response.data;
    //   } catch (error) {
    //     throw error;
    //   } 
};

const isFile = (input: any) => "File" in window && input instanceof File;
const isFileList = (input: any) =>
    "File" in window && input instanceof FileList;

export function useApiRequest() {

    const ApiRequest = async ({
        route,
        method,
        requiresAuth = false,
        body,
        formData,
        headers,
        params = {
            startIndex: 1,
            stopIndex: 1,
        }
    }: IApiRequest) => {

        let dataToSend: any = body;

        try {

            let response: any;

            const r = `${API_ROUTE}/${route}`;
            const getRoute = `${API_ROUTE}/${route}?page=${params.startIndex}`;
            // console.log(r);

            if (method === "POST") {
                response = await axios.post(r, dataToSend, {

                });
            } else if (method === "GET") {
                response = await axios.get(r, {

                });

            } else if (method === "IGET") {
                response = await axios.get(getRoute, {

                });

            } else if (method === "DELETE") {
                response = await axios.delete(r, {
                });
            } else if (method === "PUT") {
                response = await axios.put(r, dataToSend, {
                    headers: {
                        "Content-Type": "application/json"
                    }

                });
            } else if (method === "PATCH") {
                response = await axios.patch(r, dataToSend, {

                });
            }

            return response;
        } catch (error) {
            throw error;
        }
    };

    return ApiRequest;
}

export function createFormData(data: any, files?: any) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });
    if (files) {
        files.map((file: any) => {
            formData.append("images[]", file)
        })
    }

    return formData;
}

const encodeHtmlAssociations: { [key: string]: string } = {
    ["<"]: "@lt",
    [">"]: "@gt",
}
const decodeHtmlAssociations: { [key: string]: string } = {
    ["@lt"]: "<",
    ["@gt"]: ">",
}
export function encodeHtmlTags(html: string): string {
    Object.keys(encodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), encodeHtmlAssociations[key]);
    });
    return html
}

export function decodeHtmlTags(html: any): any {
    Object.keys(decodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), decodeHtmlAssociations[key]);
    });
    return html
}





// export const getDate = (date: any) => {
//     return moment(date).format("MM/DD/YYYY");
// };

export const getMoneyAmount = (amount: number) => {
    return `${amount} EUR`;
};

// export const showToast = (
//   message: string | string[],
//   type: "info" | "success" | "warning" | "error" | "default"
// ) => {
//   toast(message, {
//     type,
//     hideProgressBar: true,
//   });
// };

export const getUserPhoto = (photo: any) => {
    return photo
        ? `${process.env.NEXT_PUBLIC_STORAGE_ROUTE}/${photo}`
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
};

export const getRangeYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    let startYear = currentYear - 50;

    while (startYear <= currentYear + 50) {
        years.push(startYear++);
    }

    return years;
};

export function XformatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate() + 1).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
}

export function formatDate(dateWithTimezone: string | any) {
    const dateWithoutTime = dateWithTimezone.slice(0, 10);
    const [year, month, day] = dateWithoutTime.split('-');
    return `${day}-${month}-${year}`;
}


export function UFormatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${year}-${month}-${day}`;
}

export function formDataToObject(formData: FormData): Record<string, any> {
    let object: Record<string, any> = {};
    formData.forEach((value, key) => {
        if (object.hasOwnProperty(key)) {
            let current = object[key];
            if (!Array.isArray(current)) {
                current = object[key] = [current];
            }
            current.push(value);
        } else {
            object[key] = value;
        }
    });
    return object;
};

export const QuillModules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        [{ 'color': [] }, { 'background': [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { List: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image", "video"],
    ],
};

export const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                resolve(reader.result.toString());
            } else {
                reject('Error converting image to base64');
            }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};


export function arrayToString(items: string[]): string {
    return items.join(',');
}