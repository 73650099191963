import React, { ChangeEvent, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { QuillModules, useApiRequest } from '../../../_metronic/helpers/ApiRequest'
// import { brandProps } from '../../../_metronic/helpers/Props'
import { AddingNotify } from '../../../_metronic/helpers/Toastify'
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ProductsStyle.css'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useCategoriesContext } from '../../context/CategoriesContext'

// const categorieOptions = categorie.map((option) => option.categorie);
// const file_size = 30 * 1024 * 1024;

const Schema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string(),
    brand: Yup.string(),
    categorie: Yup.string(),
    //   image: Yup.mixed().required("image is required")
    // }).test("fileType", "Unsupported file format", (value) => {
    //   return value && ["image/jpeg", "image/png", "image/svg"].includes(value.type);
})

const AddProduct: React.FC = () => {

    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<File[]>([]);
    const [imagefile, setImage] = useState<File | any>(null);
    const [file, setFile] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState('');
    const apiRequest = useApiRequest()
    const [brands, setBrands] = useState<any[]>([]);
    // const [categories, setCategories] = useState<any[]>([]);
    const { categories } = useCategoriesContext();
    const animatedComponents = makeAnimated();
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [toSell, setToSell] = useState(1);
    const [toBuy, setToBuy] = useState(1);


    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, SetLongDescription] = useState('');


    //   const [editorState, setEditorState] = React.useState(() =>
    //   EditorState.createEmpty()
    // );

    // const editor = React.useRef<any | null>(null);
    // function focusEditor() {
    //   if (editor.current) {
    //       editor.current.focus();
    //   }
    // }

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: 'brands.json',
                method: 'GET',
            });

            setBrands(response.data)
            // setCategories(CategoryrResponse.data)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const handleCategoryChange = (event: { target: { value: React.SetStateAction<string> } }) => {
        setSelectedCategory(event.target.value);
    };

    //   const subCategories = categories.find(category => category.id === Number(selectedCategory))?.subCategory || [];

    const options = categories.map((categorie: any) => ({
        value: `api/categories/${categorie.id}`,
        label: `${categorie.label}`,
    }));

    const handleChangeSelect = (selectedOptions: any) => {
        const selectedValues = selectedOptions.map((option: any) => (option.value));
        setSelectedProducts(selectedValues);
    };


    const formik = useFormik({
        initialValues: {
            label: "",
            price: "",
            saleUnit: "",
            video_url: "",
            purchaseUnit: "",
            tax: "",
            barcodeSymbol: "",
            priority: "",
            reference: "",
            toSell: "",
            toBuy: "",
            stockAlert: "",
            desiredStock: "",
            deliveryTime: "",
            state: "",
            availableDate: "",
            // longDescription: "",
            // shortDescription: "",
            unit: "",
            type: "",
            brand: "",
            howToUse: "",
            ingredients: "",
        },

        // validationSchema: Schema,
        onSubmit: async (values, { resetForm }) => {

            const formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                formData.append("productImages[]", files[i]);
            }


            formData.append('title', values.label);
            formData.append('shortDescription', shortDescription);
            formData.append('longDescription', longDescription);
            formData.append('price', values.price);
            formData.append('stockAlert', values.stockAlert);
            formData.append('saleUnit', values.saleUnit);
            formData.append('purchaseUnit', values.purchaseUnit);
            formData.append('tax', values.tax);
            formData.append('barcodeSymbol', values.barcodeSymbol);
            formData.append('imageFile', imagefile);
            formData.append('category', selectedCategory);
            formData.append('brand', values.brand);
            formData.append('video_url', values.video_url);
            formData.append('howToUse', values.howToUse);
            formData.append('ingredients', values.ingredients);

            try {
                // const response = await apiRequest({
                //     route: `products`,
                //     method: 'POST',
                //     body: formData
                // });

                // if (response.status === 201) {
                //     AddingNotify('Produit')
                //     console.log(formData);

                // }

                console.log(formData);

                // Handle success
            } catch (error) {
                console.error(error);
                // Handle error
            }
            console.log(formData);


            // resetForm()
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        },
    })

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
    } = formik;

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#product'
                aria-expanded='true'
                aria-controls='product'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Ajouter Un Produit</h3>
                </div>
            </div>

            <div id='product' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>

                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-description-tab" data-bs-toggle="pill" data-bs-target="#pills-description" type="button" role="tab" aria-controls="pills-description" aria-selected="true">Description</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-details-tab" data-bs-toggle="pill" data-bs-target="#pills-details" type="button" role="tab" aria-controls="pills-details" aria-selected="false">Details</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-stock-tab" data-bs-toggle="pill" data-bs-target="#pills-stock" type="button" role="tab" aria-controls="pills-stock" aria-selected="false">Stock</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-pricing-tab" data-bs-toggle="pill" data-bs-target="#pills-pricing" type="button" role="tab" aria-controls="pills-pricing" aria-selected="false">Pricing</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-shipping-tab" data-bs-toggle="pill" data-bs-target="#pills-shipping" type="button" role="tab" aria-controls="pills-shipping" aria-selected="false">Shipping</button>
                            </li>

                        </ul>
                        <hr className='hr-tag' />
                        <div className="tab-content" id="pills-tabContent">

                            {/* Start Description */}
                            <div className="tab-pane fade show active" id="pills-description" role="tabpanel" aria-labelledby="pills-description-tab" tabIndex={0}>

                                <div className='row mb-6'>
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de produit</label>

                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Nom de produit'
                                            {...formik.getFieldProps('label')}
                                        />
                                        {formik.touched.label && formik.errors.label && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.label}</div>
                                            </div>
                                        )}
                                    </div>


                                </div>


                                <div className="row mb-6">
                                    <div className="col-lg-12 col-md-12 fv-row">
                                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description Courte</label>

                                        {/* <textarea
                                    className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                                    rows={6}
                                    placeholder='shortDescription'
                                    {...formik.getFieldProps('shortDescription')}

                                ></textarea> */}


                                        <ReactQuill
                                            className="react-quill-editor"
                                            theme="snow"
                                            value={shortDescription}
                                            onChange={(e) => setShortDescription(e)}
                                            modules={QuillModules}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <div className="col-lg-12 col-md-12 fv-row">
                                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description longue</label>

                                        {/* <textarea
                                    className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                                    rows={6}
                                    placeholder='description'
                                    {...formik.getFieldProps('description')}

                                ></textarea> */}

                                        <ReactQuill
                                            className="react-quill-editor"
                                            theme="snow"
                                            value={longDescription}
                                            onChange={(e) => SetLongDescription(e)}
                                            modules={QuillModules}
                                        />

                                    </div>
                                </div>

                                <div className='row mb-6'>

                                    <div className='col-lg-4 col-md-4 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Categories</label>
                                        {
                                            categories.length > 0 || true ?
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    isMulti
                                                    options={options}
                                                    onChange={handleChangeSelect}
                                                />
                                                :
                                                ''
                                        }
                                    </div>

                                    <div className='col-lg-4 col-md-4 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Marque</label>
                                        <select
                                            // name="brand"
                                            className="form-select"
                                            {...formik.getFieldProps('brand')}
                                        >
                                            <option value="">brand</option>
                                            {brands.map((brand: any, index) => {
                                                return (
                                                    <option key={index} value={brand.id}>
                                                        {brand.title}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div className='col-lg-4 col-md-4 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Lien de Video</label>

                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Lien du Video'
                                            {...formik.getFieldProps('video_url')}
                                        />
                                        {formik.touched.video_url && formik.errors.video_url && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.video_url}</div>
                                            </div>
                                        )}
                                    </div>



                                </div>

                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Miniature de produit</label>

                                        <input
                                            type="file"
                                            name="image"
                                            // accept='image/png, image/jpeg, image/svg+xml'
                                            accept=".png, .jpg, .jpeg"
                                            required
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 p-0 ps-3'
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                // const reader = new FileReader();
                                                // reader.onload=function(e){
                                                //   setUrl(e.target?.result)
                                                // }
                                                // reader.readAsDataURL(e.target.files[0])

                                                if (e.target.files && e.target.files[0]) {
                                                    setFile(URL.createObjectURL(e.target.files[0]));
                                                    setImage(e.target.files[0]);
                                                }
                                            }} />
                                    </div>

                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Autre Images</label>

                                        <input
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const { files } = e.target;
                                                if (files) {
                                                    setFiles(Array.from(files));
                                                }
                                            }}
                                            type="file"
                                            name="productImages"
                                            accept=".png, .jpg, .jpeg"
                                            className='form-control form-control-lg form-control-solid p-0 ps-3'
                                            multiple
                                        />
                                    </div>


                                </div>
                            </div>
                            {/* End Description */}

                            {/* Start Details */}
                            <div className="tab-pane fade" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab" tabIndex={0}>
                                <div className="row mb-6">

                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Reference</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Reference'
                                            {...formik.getFieldProps('reference')}
                                        />
                                        {formik.touched.reference && formik.errors.reference && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.reference}</div>
                                            </div>
                                        )}
                                    </div>

                                </div>

                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Comment utiliser</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Comment utiliser'
                                            {...formik.getFieldProps('howToUse')}
                                        />
                                        {formik.touched.howToUse && formik.errors.howToUse && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.howToUse}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Ingrédients</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Ingrédients'
                                            {...formik.getFieldProps('ingrédients')}
                                        />
                                        {formik.touched.ingredients && formik.errors.ingredients && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ingredients}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Barcode Symbol</label>

                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Barcode Symbol'
                                            {...formik.getFieldProps('barcodeSymbol')}
                                        />
                                        {formik.touched.barcodeSymbol && formik.errors.barcodeSymbol && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.barcodeSymbol}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* End Details */}

                            {/* Start Stock */}
                            <div className="tab-pane fade" id="pills-stock" role="tabpanel" aria-labelledby="pills-stock-tab" tabIndex={0}>
                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Unité de vente</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Unité de vente'
                                            {...formik.getFieldProps('saleUnit')}
                                        />
                                        {formik.touched.saleUnit && formik.errors.saleUnit && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.saleUnit}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Stock Alert</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Stock Alert'
                                            {...formik.getFieldProps('stockAlert')}
                                        />
                                        {formik.touched.stockAlert && formik.errors.stockAlert && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.stockAlert}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Stock désiré</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Stock désiré'
                                            {...formik.getFieldProps('desiredStock')}
                                        />
                                        {formik.touched.desiredStock && formik.errors.desiredStock && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.desiredStock}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>

                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Type</label>
                                        <select
                                            // name="brand"
                                            className="form-select"
                                            {...formik.getFieldProps('type')}
                                        >
                                            <option value="standard">Standard</option>
                                            <option value="package">Pack</option>
                                            <option value="virtual">Virtual</option>
                                            <option value="Variant">Variant</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mb-6'>

                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Date de disponibilité</label>
                                        <input
                                            type='date'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Date de disponibilité'
                                            // min={new Date().toISOString().split('T')[0]}
                                            {...formik.getFieldProps('availableDate')}
                                        />
                                        {formik.touched.availableDate && formik.errors.availableDate && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.availableDate}</div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            {/* End Stock */}

                            {/* Start Pricing */}
                            <div className="tab-pane fade" id="pills-pricing" role="tabpanel" aria-labelledby="pills-pricing-tab" tabIndex={0}>
                                <div className='row mb-6'>

                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Prix de produit</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Prix'
                                            {...formik.getFieldProps('price')}
                                        />
                                        {formik.touched.price && formik.errors.price && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.price}</div>
                                            </div>
                                        )}
                                    </div>


                                </div>

                                <div className="row mb-6">
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Tax</label>

                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Tax'
                                            {...formik.getFieldProps('tax')}
                                        />
                                        {formik.touched.tax && formik.errors.tax && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.tax}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6 text-capitalize'>
                                            à vendre
                                        </label>
                                        <div className='form-check form-check-custom form-check-solid form-check-info'>
                                            <input
                                                type='radio'
                                                name='toSell'
                                                id='activeToSell'
                                                className='form-check-input'
                                                checked={toSell === 1}
                                                onChange={() => setToSell(1)}
                                                onBlur={handleBlur}
                                                value={1}
                                            />
                                            <label htmlFor='activeToSell' className='ms-2 col-form-label fw-bold'>
                                                Active
                                            </label>

                                            <input
                                                type='radio'
                                                name='toSell'
                                                id='desactiveToSell'
                                                className='form-check-input ms-2'
                                                checked={toSell === 0}
                                                onChange={() => setToSell(0)}
                                                onBlur={handleBlur}
                                                value={0}
                                            />
                                            <label htmlFor='desactiveToSell' className='ms-2 col-form-label fw-bold'>
                                                Desactive
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6 text-capitalize'>
                                            Pour acheter
                                        </label>
                                        <div className='form-check form-check-custom form-check-solid form-check-info'>
                                            <input
                                                type='radio'
                                                name='toBuy'
                                                id='activeToBuy'
                                                className='form-check-input'
                                                checked={toBuy === 1}
                                                onChange={() => setToBuy(1)}
                                                onBlur={handleBlur}
                                                value={1}
                                            />
                                            <label htmlFor='activeToBuy' className='ms-2 col-form-label fw-bold'>
                                                Active
                                            </label>

                                            <input
                                                type='radio'
                                                name='toBuy'
                                                id='desactiveToBuy'
                                                className='form-check-input ms-2'
                                                checked={toBuy === 0}
                                                onChange={() => setToBuy(0)}
                                                onBlur={handleBlur}
                                                value={0}
                                            />
                                            <label htmlFor='desactiveToBuy' className='ms-2 col-form-label fw-bold'>
                                                Desactive
                                            </label>

                                            {/* {touched.status && errors.status && (
<div className='fv-plugins-message-container'>
<div className='fv-help-block'>{errors.status}</div>
</div>
)} */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* End Pricing */}

                            {/* Start Shepping */}
                            <div className="tab-pane fade" id="pills-shipping" role="tabpanel" aria-labelledby="pills-shipping-tab" tabIndex={0}>

                                <div className='row mb-6'>

                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Priority</label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Prix'
                                            {...formik.getFieldProps('priority')}
                                        />
                                        {formik.touched.priority && formik.errors.priority && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.priority}</div>
                                            </div>
                                        )}
                                    </div>


                                </div>

                                <div className='row mb-6'>

                                    <div className='col-lg-6 col-md-6 fv-row'>
                                        <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Temps de livraison</label>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Prix'
                                            {...formik.getFieldProps('deliveryTime')}
                                        />
                                        {formik.touched.deliveryTime && formik.errors.deliveryTime && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.deliveryTime}</div>
                                            </div>
                                        )}
                                    </div>


                                </div>
                            </div>
                            {/* End Shepping */}


                        </div>


                        <div className='row mb-6 d-none'>
                            <div className='col-lg-6 col-md-6 fv-row'>
                                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Prix de produit</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Prix'
                                    {...formik.getFieldProps('price')}
                                />
                                {formik.touched.price && formik.errors.price && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.price}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6 d-none'>
                            <div className='col-lg-6 col-md-6 fv-row'>
                                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Unité</label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Unité'
                                    {...formik.getFieldProps('unit')}
                                />
                                {formik.touched.unit && formik.errors.unit && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.unit}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Enregistrer les modifications'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddProduct
