import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useApiRequest } from '../../_metronic/helpers/ApiRequest';
// import DataFetcher from '../modules/DataFetcher';
// import { CategorieProps } from '../../_metronic/helpers/Props';


// interface CategorieProps {
//     id: number;
//     title: string;
//     description: string;
//     image: string;
//     parent_id: string;
//     slug: string;
// }



const CategoriesContext = createContext<{
    categories: any[];
    loading: boolean;
}>({
    categories: [],
    loading: true
});

export const useCategoriesContext = () => useContext(CategoriesContext);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [categories, setCategories] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const apiRequest = useApiRequest()

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: 'categories.json',
                method: 'GET',
            });

            setCategories(response.data.data)
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>

            <CategoriesContext.Provider value={{ categories, loading }}>
                {children}
            </CategoriesContext.Provider>
        </>


    );
};
