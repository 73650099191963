import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers'

type Props = {
  placeholder: string,
  searchQuery: any
}
const SearchComponent: React.FC<Props> = ({ placeholder, searchQuery }) => {


  return (


    <div className='d-flex align-items-center position-relative my-1'>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-1 position-absolute ms-6'
      />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className={'form-control form-control-solid w-250px ps-14 '}
        placeholder={placeholder}
        value={searchQuery[0].searchTerm}
        onChange={(e) => searchQuery[1](e.target.value)}
      />

    </div>



  )
}

export default SearchComponent