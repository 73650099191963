import React, {useState, useEffect} from 'react'
import {Field, Form, Formik} from 'formik'
import {ToastContainer, toast} from 'react-toastify'
import {KTSVG} from '../../../_metronic/helpers'
import {Loading} from '../Loading'
import {useAuth} from '../../modules/auth'
import {createFormData, useCallApi} from '../../modules/utils'
import {Charges} from '../../pages/charges/charges'
interface Role {
  id: number
  attributes: {
    display_name: string
  }
}
export const ModalAddCharges = () => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [typeCharge, setTypeCharge] = useState<any>([])
  const [chejed, setchejed] = useState(false)
  const [currentTime, setCurrentTime] = useState('')

  const initialValues = {
    date: new Date().toLocaleDateString(),
    warehouse_id: 1,
    expense_category_id: '',
    amount: '',
    details: '',
  }

  const gettypeCharge = async () => {
    try {
      const data = await callApi({
        route: 'api/expense-categories',

        method: 'GET',
      })
      setTypeCharge(data.data.data)
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  useEffect(() => {
    gettypeCharge()
  }, [])

  const handleSubmit = async (values: any) => {
    setLoading(true)
    const dataToSend = {
      date: new Date().toLocaleDateString(),
      warehouse_id: 1,
      expense_category_id: values.expense_category_id,
      amount: values.amount,
      details: values.details,
      active: values.status ? 1 : 0,
    }
    console.log(dataToSend)

    try {
      console.log(dataToSend)
      const data = await callApi({
        route: 'api/expenses',
        method: 'POST',
        body: dataToSend,
      })
      toast.success('Charge avec succès')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      <div
        className='modal fade'
        id='ModalAddUtilisateur'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' >
          <Formik
            initialValues={initialValues}
            onSubmit={(values, {resetForm}) => {
              handleSubmit(values)
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header ' >
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouvelle charge
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Type de charge :
                            </label>
                            <Field
                              as='select'
                              name='expense_category_id'
                              className='form-select'
                              aria-label='Default select example'
                            >
                              <option value=''>Choisir..</option>
                              {typeCharge &&
                                typeCharge.map((charge: any, index: number) => {
                                  return (
                                    <option key={index} value={charge.id}>
                                      {charge.attributes.name}
                                    </option>
                                  )
                                })}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='note' className='fw-bold'>
                              Note :
                            </label>
                            <Field
                              name='details'
                              type='text'
                              className='form-control'
                              id='note'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Total TTC :
                            </label>
                            <Field
                              name='amount'
                              type='number'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Montant TVA :
                            </label>
                            <Field
                              name='totaltva'
                              type='number'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col'>
                          <div className='form-group d-flex fw-bold'>
                            {' '}
                            <label htmlFor='exampleInputEmail1'>Paiement :</label>
                            <div className='form-check form-switch'>
                              <Field
                                className='form-check-input'
                                type='checkbox'
                                name='status'
                                role='switch'
                                //   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                //     const { checked } = e.target;
                                //     setchejed(checked)
                                //   }}
                                id='flexSwitchCheckDefault'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary'>
                        <Loading />
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-primary'>
                        valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
