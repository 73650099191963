import { Field, Form, Formik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { createFormData, useCallApi } from '../../modules/utils';
import { useAuth } from '../../modules/auth';
import { Loading } from '../Loading';
import { toast } from 'react-toastify';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { checkPrimeSync } from 'crypto';

const PrettoSlider = styled(Slider)({
  // Styles for the slider component
});

type Props = {
  data: any;
  // deleteVille:(id:number)=>void
};

export const ModalAttachPromo: React.FC<Props> = ({ data }) => {
  const animatedComponents = makeAnimated();
  const [products, setProducts] = useState<any>([])

  const callApi = useCallApi();
  const [loading, setLoading] = useState(false);
  const [checked, setchecked] = useState(false);
  const [value, setValue] = useState(20);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]); // State for selected category IDs

  const handleChange = (e: any) => {
    setValue(e.target.value);
    console.log(e.target.value);
  };
  console.log(value);

  const options = products.map((category: any) => ({
    value: category.id,
    label: category.attributes.name,
  }));

  const handleChangeSelect = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => ({ product_id: option.value }));
    setSelectedCategories(selectedValues);
    console.log(selectedValues);
  };
  

  const initialValues = {
    description: '',
    start_date: '',
    end_date: '',
    discount: 0,
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setchecked(e.target.checked);
  };

  const handelSumit = async (values: any) => {
      setLoading(true);
    const dataToSend = { all: checked, product_items: selectedCategories, promotion_id: data.data.id };
  
    console.log(dataToSend);
    if (selectedCategories.length === 0 && checked === false) {
      toast.error('Aucun produit choisi pour cette promotion');
      setLoading(false);
    } else {
      try {
        const dat = await callApi({
          route: 'api/attach-promo',
          method: 'POST',
          body: dataToSend,
        });
        toast.success('Ajouté avec succès');
        data.getPromotions();
        setLoading(false);
        getProducts(); // Move getProducts() here to ensure it's called after the API call is completed
      } catch (error: any) {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    }
  };
  
 const getProducts = async () => {
    const {data} = await callApi({
      route: 'api/products?promo='+true,
      method: 'GET',
    })
    setProducts(data.data)
    // setLoading(false)
  }
  useEffect(() => {
    getProducts()

}, [])

  return (
    <>
      <div
        className="modal fade"
        id={'modalattachpromo' + data.data.id}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document" style={{ maxWidth: '40%' }}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, { resetForm }) => {
              handelSumit(values);
            }}
          >
            {() => (
              <Form className="modal-content">
                <div className="modal-content">
                  <div className="modal-header " style={{ background: 'var(--kt-primary)' }}>
                    <h1 className="modal-title fs-5 text-white" id="exampleModalLabel">
                      Attacher une promotion :
                    </h1>
                    <button
                      type="button"
                      className="btn-close text-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row mb-4">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1" className="fw-bold mb-4">
                              <span>Produits :</span>
                             
                            </label>
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={options}
                              onChange={handleChangeSelect}
                              isDisabled={checked} // Disable the select when checked is true
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1" className="fw-bold mb-4 me-3">
                          Promotion pour tous les produits
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            name="notifications"
                            defaultChecked={false}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                      Annuler
                    </button>
                    {loading ? (
                      <button type="button" className="btn btn-primary">
                        <Loading />
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Attacher
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
