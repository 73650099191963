import React, { useState, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCategoriesContext } from '../../context/CategoriesContext';
import { QuillModules, useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import { AddingNotify } from '../../../_metronic/helpers/Toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Schema = Yup.object().shape({
    label: Yup.string().required('Le nom de categorie est obligatoire!'),
    description: Yup.string(),
    parent: Yup.string(),
    // image: Yup.mixed().required("image is required")
    // }).test("fileType", "Unsupported file format", (value) => {
    //   return value && ["image/jpeg", "image/png", "image/svg"].includes(value.type);
})

const AddCategorie: React.FC = () => {

    const [loading, setLoading] = useState(false)
    const [imagefile, setImage] = useState<File | any>(null);
    const [file, setFile] = useState<string>("");
    const { categories } = useCategoriesContext();
    const apiRequest = useApiRequest()
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState<File[]>([]);

    const formik = useFormik({
        initialValues: {
            label: "",
            parent: ""

        },
        validationSchema: Schema,
        onSubmit: async (values, { resetForm }) => {

            const formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                formData.append("categoryImages[]", files[i]);
            }

            formData.append('label', values.label);
            formData.append('description', description);
            formData.append('imageFile', imagefile);
            formData.append('parent', values.parent);

            try {
                const response = await apiRequest({
                    route: `categories`,
                    method: 'POST',
                    body: formData
                });

                if (response.status === 201) {
                    AddingNotify('Categorie')
                }

            } catch (error) {
                console.error(error);
                // Handle error
            }

            resetForm()
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#product'
                aria-expanded='true'
                aria-controls='product'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Ajouter Une Categorie</h3>
                </div>
            </div>

            <div id='product' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>

                    <div className='card-body border-top p-9'>

                        <div className='row mb-6'>
                            <div className='col-lg-6 col-md-6 fv-row'>
                                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de categorie</label>

                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Nom de categorie'
                                    {...formik.getFieldProps('label')}
                                />
                                {formik.touched.label && formik.errors.label && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.label}</div>
                                    </div>
                                )}
                            </div>

                            <div className='col-lg-6 col-md-6 fv-row'>
                                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Parent</label>

                                <select
                                    // name="categorie"
                                    className="form-select"
                                    value={formik.values.parent}
                                    onChange={(event) => {
                                        formik.setFieldValue('parent', event.target.value);
                                    }}
                                >
                                    <option value="parent">Parent</option>
                                    {categories.map((categorie: any, index) => {
                                        return (
                                            <option key={index} value={categorie.id}>
                                                {categorie.title}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>


                        </div>


                        <div className="row mb-6">
                            <div className="col-lg-12 col-md-12 fv-row">
                                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                                <ReactQuill
                                    className="react-quill-editor"
                                    theme="snow"
                                    value={description}
                                    onChange={(e) => setDescription(e)}
                                    modules={QuillModules}
                                />

                            </div>
                        </div>
                        <div className='row mb-6'>

                            <div className='col-lg-6 col-md-6 fv-row'>
                                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Miniature de categorie</label>

                                <input
                                    type="file"
                                    name="image"
                                    accept=".png, .jpg, .jpeg, .webp"
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0  p-0 ps-3'
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {

                                        if (e.target.files && e.target.files[0]) {
                                            setFile(URL.createObjectURL(e.target.files[0]));
                                            setImage(e.target.files[0]);
                                        }
                                    }} />
                                {formik.values.label && imagefile === null && formik.values.parent !== "parent" && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>L'image de categorie est obligatoire!</div>
                                    </div>
                                )}

                            </div>

                            <div className='col-lg-6 col-md-6 fv-row'>
                                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Autre Images</label>

                                <input
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const { files } = e.target;
                                        if (files) {
                                            setFiles(Array.from(files));
                                        }
                                    }}
                                    type="file"
                                    name="productImages"
                                    accept=".png, .jpg, .jpeg"
                                    className='form-control form-control-lg form-control-solid p-0 ps-3'
                                    multiple
                                />
                            </div>


                        </div>

                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={imagefile === null || loading ? false : false}>
                            {!loading && 'Enregistrer les modifications'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddCategorie