import React from 'react'
import { ListVentes } from '../../components/journalVente/ListVentes'

export const ListVente = () => {
  return (
    <>
      <ListVentes />
    </>
  )
}
