import React, {ChangeEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {createFormData, useCallApi} from '../../modules/utils'
import {number, string} from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import {Loading} from '../Loading'
import {check} from 'prettier'
import {useAuth} from '../../modules/auth'
import { Box, Typography } from '@mui/material'
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
type Props = {
  data: any

  // deleteVille:(id:number)=>void
}
const PrettoSlider = styled(Slider)({
    color: '#009ef7',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#009ef7',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });
export const ModalUpdateProimotions: React.FC<Props> = ({data}) => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [image, setImage] = useState<File | any>([])
  const [value, setValue] = useState(20);

  const [filess, setFiles] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [promotionupdate, setpromotionUpdate] = useState({
    discount: '',
    description: '',
    start_date: '',
    end_date: '',
  })
  const handleChange = (e:any) => {
    setValue(e.target.value);
    console.log(e.target.value);
    
  };
  useEffect(() => {
    setpromotionUpdate({
      discount: data?.discount,
      description: data?.description,
      start_date: data?.start_date,

      end_date: data?.end_date,
    })
  }, [data.id])

  console.log(promotionupdate)
  
  // const getCategories = async () => {
  //     const {data} = await callApi({
  //       route: 'api/product-categories',
  //       method: 'GET',
  //     })
  //     setCategories(data.data)
  //     // setLoading(false)
  //   }
  const updatePromotion = async () => {
    setLoading(true)
    const dataTosend = {
        discount: data?.discount,
        description: data?.description,
        start_date: data?.start_date,
        end_date: data?.end_date,
    }

    // const formData = createFormData(dataTosend, image)
    try {
      const dat = await callApi({
        route: `api/promotions/` + data.id,
        method: 'PUT',
        body: promotionupdate,
      })
      toast.success('modifié avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }

    // setLoading(false)
    // Toast.success('modifié')
  }

  return (
    <>
      <div
        className='modal fade'
        id={'ModalUpdatePromo' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' >
          <div className='modal-content'>
            <div className='modal-header ' >
              <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                Modification de la promotion: 
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' style={{overflowY: 'scroll'}}>
              <form>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Description :
                      </label>
                      <input
                        type='text'
                        value={promotionupdate?.description}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setpromotionUpdate({...promotionupdate, description: e.target.value})
                        }
                      />
                    </div>
                  </div>
                  
                </div>
                <div className="row mb-4">
                <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleColorInput' className='fw-bold'>
                        Taux de la promotion
                      </label>
                      <div className="d-flex">
               <Box sx={{ width: 320,display:"flex" }}>
      <PrettoSlider
        value={value}
        onChange={(e: any) =>setpromotionUpdate({...promotionupdate, discount: e.target.value})}      
            valueLabelDisplay="auto"
        aria-label="pretto slider"
        // defaultValue={20}
      />
      <Typography>{promotionupdate?.discount }%</Typography>
    </Box>
                      </div>
       
                      
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                        <div className="col">
                        <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Date début :
                          </label>
                          <input
                            type='datetime-local'
                            name='start_date'
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                            value={promotionupdate?.start_date}
                            onChange={(e: any) =>
                             setpromotionUpdate({...promotionupdate, start_date: e.target.value})
                        }
                          />
                        </div>
                        </div>
                        <div className="col">
                        <div className='form-group'>
                          <label htmlFor='exampleInputEmail1' className='fw-bold'>
                            Date fin :
                          </label>
                          <input
                            type='datetime-local'
                            name='end_date'
                            value={promotionupdate?.end_date}
                            onChange={(e: any) =>
                             setpromotionUpdate({...promotionupdate, end_date: e.target.value})
                        }
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                        </div>
                        </div>
                     
                      </div>
               
              
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='button' onClick={updatePromotion} className='btn btn-primary'>
                  valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
