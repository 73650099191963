import React, { useEffect, useState } from 'react'
import SearchComponent from '../SearchComponent'
import { KTSVG } from '../../../_metronic/helpers'
import { useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'
import { ItemVentes } from './ItemVentes'
import { Logout, useAuth } from '../../modules/auth'
import { FilterCmd } from './FilterCmd'
import { LuBarChart2 } from 'react-icons/lu'
import { MdPermContactCalendar, MdAccountBalanceWallet, MdRemoveShoppingCart } from 'react-icons/md'
import '../../css/app.css'

export const ListVentes = () => {
  const [ventes, setVentes] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterventes, setFilterVentes] = useState<any>()
  const { auth, logout, setCurrentUser, hasPermission } = useAuth()

  const callApi = useCallApi()
  const getventes = async (
    caissier: any,
    status: any,
    type: any,
    payment_status: any,

    filter: any,
    start_date: any,
    end_date: any,
    returned: any
  ) => {
    try {
      console.log(returned);

      setLoading(true)

      const { data } = await callApi({
        route: `api/sales-for-each-days/${filter ? filter : 'day'}?payment_status=${payment_status}&type=${type}&status=${status}&date=&caissier=${caissier}&start_date=${start_date}&end_date=${end_date}&filter[returned]=${returned}&comptabilise=${hasPermission("manage_sales_invoiced") && hasPermission("manage_sale") ? "" : true}`,
        method: 'GET',
      })

      const initialventes = data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setVentes(initialventes)
      setLoading(false)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
    } catch (error: any) {
      console.log(error.response.data.message == 'Unauthenticated.')

      // logout()
      console.error('Error fetching product data:')
    }
  }

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedventes = filterventes.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedventes.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
      console.log(checkedProductIds)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedventes
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckedProductIds(checkedIds)
    setFilterVentes(updatedventes)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedventes = filterventes.map((product: any) =>
        product.id === productId ? { ...product, checked } : product
      )
      setFilterVentes(updatedventes)
      const checkedIds = updatedventes
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
      setCheckedProductIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
    ids.map(async (id: number) => {
      if (window.confirm('Voulez-vous vraiment supprimer ce produit !' + id)) {
        const filteredposts = ventes.filter((product: any) => product.id !== id)
        setFilterVentes(filteredposts)
        toast.success('Produit   supprimé')
        const { data } = await callApi({
          route: 'api/ventes/' + id,
          method: 'DELETE',
        })
      }
    })
  }
  const getSubVenteByStatus = (
    caissier: any,
    status: any,
    type: any,
    payment_status: any,

    filter: any = "day",
    start_date: any,
    end_date: any,
    returned: any
  ) => {



    if (status || caissier || type || payment_status || start_date || end_date || filter || returned != null) {
      console.log(returned);


      getventes(caissier, status, type, payment_status, filter, start_date, end_date, returned)
    } else {
      console.log(returned);

      setLoading(true)
      setFilterVentes(ventes)
      setLoading(false)
    }

  }

  useEffect(() => {
    getventes("", "", "", "", "day", "", "", "")
  }, [])

  useEffect(() => {
    setFilterVentes(ventes)
  }, [ventes])


  return (
    <>

      <div className={`card mb-4 py-5`}>
        {/* begin::Header */}
        <div className='card-header d-block border-0 pt-5 d-none'>

          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Journal des Ventes</span>
          </h3>
          <div
            className='card-toolbar justify-content-end'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
          </div>
        </div>

        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive d-none'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted d-none'>
                  {/* <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterventes.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th> */}
                  {/* <th className="min-w-150px">Pays</th> */}
                  <th className=' text-center'>Date d'ouverture</th>

                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>text</td>
                  <td>text</td>
                  <td>text</td>
                  <td>text</td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
            {/* end::Table */}
          </div>
          {/* end::Table container */}


          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-3">
                <div className=''>
                  <span><LuBarChart2 className='custom-icon' /> Conversion Rate</span>
                  <p className='card-text'>0% <span className='fs-5 text-gray-500'>day</span></p>
                </div>
              </div>
              <div className="col-3">
                <div className=''>

                  <span><MdRemoveShoppingCart className='custom-icon' /> Abandoned Carts</span>
                  <p className='card-text'>0% <span className='fs-5 text-gray-500'>day</span></p>
                </div>
              </div>
              <div className="col-3">
                <div className=''>

                  <span><MdAccountBalanceWallet className='custom-icon' /> Average Order Value</span>
                  <p className='card-text'>0% <span className='fs-5 text-gray-500'>day</span></p>
                </div>
              </div>
              <div className="col-3">
                <div className=''>

                  <span><MdPermContactCalendar className='custom-icon' /> Net Profit per Visit</span>
                  <p className='card-text'>0% <span className='fs-5 text-gray-500'>day</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>

      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header d-block border-0 pt-5'>

          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Journal des Ventes (0)</span>
          </h3>
          <div
            className='card-toolbar justify-content-start'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <>
              {/* begin::Filter Button */}

              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selected
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteProduct(checkedProductIds)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                <FilterCmd bysale={true} getSubVenteByStatus={getSubVenteByStatus} />
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {/* <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterventes.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th> */}
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className=' text-center'>Date d'ouverture</th>
                    {
                      hasPermission("manage_dashboard") ?
                        <th className=' text-center'>Chiffre d'affaire</th>
                        : <></>
                    }

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterventes.map((product: any, index: any) => {
                    return (
                      <ItemVentes
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
