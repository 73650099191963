import React, { startTransition, useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useCallApi } from '../../modules/utils'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
  bysale: boolean
  getSubVenteByStatus: (
    status: string,
    caissier: any,
    type: any,
    payment_status: any,
    filter: any,
    start_date?: any,
    end_date?: any,
    returned?: any
  ) => void
}
export const FilterCmd: React.FC<Props> = ({ getSubVenteByStatus, bysale }) => {
  const callApi = useCallApi()
  const [caissiers, setCaissiers] = useState<any[]>([])
  const [caissier, setCaissier] = useState('')
  const [status, setStatus] = useState('')
  const [type, setType] = useState('')
  const [payment_status, setPayment_status] = useState('')
  const [start_date, setStartDate] = useState('')
  const [end_date, setEndDate] = useState('')
  const [returned, setReturned] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()

  const [filter, setFilter] = useState('')

  const getCaissiers = async () => {
    try {
      const { data } = await callApi({
        route: 'api/users?role=caissier',
        method: 'GET',
      })

      const initialsubventes = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setCaissiers(initialsubventes)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getCaissiers()
  }, [])

  return (
    <>
      <div className="row w-100">
        {bysale ?
          (
            <>

              <div className="col-3">
                <label className=' fs-7 text-gray-500 fw-bold'>Numéro de commande</label>
                <input className="form-control " type="search" aria-label="Search"
                 placeholder='Numéro de commande'
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)} />
              </div>

              <div className='col-2'>
                <label className=' fs-7 text-gray-500 fw-bold'>Date Début:</label>
                <input
                  type='date'
                  className='form-control  form-control-solid'
                  onChange={(e) => {
                    setStartDate(e.target.value)
                    getSubVenteByStatus(
                      status,
                      caissier,
                      type,
                      payment_status,
                      filter,
                      e.target.value,
                      end_date,
                      returned
                    )
                  }}
                // onBlur={handleBlur}
                // value={values.cp_date_debut}
                />
              </div>
              <div className='col-2'>
                <label className=' fs-7 text-gray-500 fw-bold'>Date Fin:</label>

                <input
                  type='date'
                  name=''
                  className='form-control  form-control-solid'
                  min={start_date}
                  onChange={(e) => {
                    setEndDate(e.target.value)

                    getSubVenteByStatus(
                      status,
                      caissier,
                      type,
                      payment_status,
                      filter,
                      start_date,
                      e.target.value,
                      returned
                    )
                  }}
                // onBlur={handleBlur}
                // value={values.cp_date_debut}
                />
              </div>
              <div className='col-2'>
                <label className=' fs-7 text-gray-500 fw-bold text-bg-white'></label>

                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => {
                    setFilter(e.target.value)
                    getSubVenteByStatus(
                      status,
                      caissier,
                      type,
                      payment_status,
                      e.target.value,
                      start_date,
                      end_date,
                      returned
                    )
                  }}
                >
                  <option value='day'> Par Jour </option>
                  <option value='month'> Par Mois</option>
                  <option value='year'> Par Année</option>
                </select>
              </div>

              <div className='col-2'>
                <label className=' fs-3 fw-bold '></label>
                <br />

                <button
                  // disabled={isLoading}
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                >
                  <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                  Plus des Filtres
                </button>
                {/* end::Filter Button */}
                {/* begin::SubMenu */}
                <div className='menu menu-sub menu-sub-dropdown  ' data-kt-menu='true'>
                  {/* begin::Header */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Options Filtre</div>
                  </div>
                  {/* end::Header */}

                  {/* begin::Separator */}
                  <div className='separator border-gray-200'></div>
                  {/* end::Separator */}

                  {/* begin::Content */}
                  <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    {/* begin::Input group */}
                    <div className='row'>
                      <div className='mb-10 col'>
                        <label className=' fs-7 text-gray-500 fw-bold'>Statut:</label>
                        <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value=''>Tous</option>
                          <option value='en cours'>En Cours</option>
                          <option value='valide'>Validée</option>
                          <option value='annuler'>Annulée</option>
                        </select>
                      </div>
                      <div className='mb-10 col'>
                        <label className=' fs-7 text-gray-500 fw-bold'>Type:</label>
                        <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value={''}>Tous</option>
                          <option value={'sur Place'}>Sur Place</option>
                          <option value={'livraison'}>Livraison</option>
                        </select>
                      </div>
                      <div className='mb-10 col'>
                        <label className=' fs-7 text-gray-500 fw-bold'>Paiement:</label>
                        <select
                          className='form-select form-select-solid fw-bolder'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          data-kt-user-table-filter='role'
                          data-hide-search='true'
                          onChange={(e) => setPayment_status(e.target.value)}
                        >
                          <option value={''}>Tous</option>
                          <option value={1}>Payé</option>
                          <option value={0}>Non Payé</option>
                        </select>
                      </div>
                    </div>
                    <div className='row'>
                      <div></div>
                      <div className='form-check form-switch col'>
                        <input
                          className='form-check-input'
                          onChange={(e) => {
                            setReturned(!returned)
                            // getSubVenteByStatus(status,caissier,type,payment_status,filter,start_date,end_date,!returned ?0:1)
                          }}
                          type='checkbox'
                          name='status'
                          role='switch'
                          id='flexSwitchCheckDefault'
                        />
                        <label className=' col  fs-7 text-gray-500 fw-bold'>Retournée </label>
                      </div>
                    </div>

                    {/* end::Input group */}

                    {/* begin::Input group */}
                    {/* <div className='mb-10'>
              <label className=' fs-7 text-gray-500 fw-bold'>Last login:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
               
              >
                <option value=''></option>
                <option value='Yesterday'>Yesterday</option>
                <option value='20 mins ago'>20 mins ago</option>
                <option value='5 hours ago'>5 hours ago</option>
                <option value='2 days ago'>2 days ago</option>
              </select>
            </div> */}
                    {/* end::Input group */}

                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end'>
                      <button
                        // disabled={isLoading}
                        type='button'
                        onClick={() =>
                          getSubVenteByStatus(
                            caissier,
                            status,
                            type,
                            payment_status,
                            filter,
                            start_date,
                            end_date,
                            returned ? 0 : 1
                          )
                        }
                        className='btn btn-primary fw-bold px-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='filter'
                      >
                        Appliquer
                      </button>
                    </div>
                    {/* end::Actions */}
                  </div>

                  {/* end::Content */}
                </div>

                <Link to={'/ventes/journal-de-vente'} className='btn btn-sm btn-primary shadow-sm mr-1 d-none'>
                  <i className='fas fa-arrow-circle-left'></i>
                  <span className='d-none d-sm-inline-block font-weight-bold'>Retour</span>
                </Link>
              </div>
            </>
          ) : (
            <></>
          )}

        {/* <div className=' d-none'>
          <label className=' fs-7 text-gray-500 fw-bold text-bg-white'></label>

          <select
            className='form-select form-select-solid  fw-bolder'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='role'
            data-hide-search='true'
            onChange={(e) => {
              setCaissier(e.target.value)
              getSubVenteByStatus(
                e.target.value,
                status,
                type,
                payment_status,
                filter,
                start_date,
                end_date,
                returned
              )
            }}
          >
            <option value=''>Vendeur / Vendeuse</option>
            {caissiers.map((caissier) => {
              return (
                <option value={caissier.id}>
                  {caissier.attributes.first_name + ' ' + caissier.attributes.last_name}
                </option>
              )
            })}
          </select>
        </div> */}
      </div>



    </>
  )
}
