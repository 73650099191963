import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import { createFormData, useCallApi } from '../../modules/utils'
import { number, string } from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import { Loading } from '../Loading'
import { check } from 'prettier'
import { useAuth } from '../../modules/auth'

type Props = {
  data: any
  loadProduct:()=>void
  // deleteVille:(id:number)=>void
}
export const ModalUpdateProduct: React.FC<Props> = ({data,loadProduct}) => {

  const {categories,getProducts} = useAuth()
    const callApi = useCallApi() 
    const [image, setImage] = useState<File | any>([]);
    const modalVisible = useRef<HTMLButtonElement>(null);

    const [filess, setFiles] = useState<string>()
        const [loading, setLoading] = useState(false)
     const [chejed, setchejed] = useState(data.attributes.status==="1")
    const [productupdate, setProductUpdate] = useState<any>()
    useEffect(() => {
     setProductUpdate(
      {
        name:data?.attributes.name,
        code: data?.attributes.code,
        product_category_id: data?.attributes.product_category_id,
        product_price: data?.attributes.product_price,
        notes: data?.attributes.notes,
        status: data?.attributes.status,
        price_purchase: data?.attributes.price_purchase,
        nbrpoints:data?.attributes.nbrpoints
  
      
      }
     )
    }, [data.id])
    
    // const getCategories = async () => {
    //     const {data} = await callApi({
    //       route: 'api/product-categories',
    //       method: 'GET',
    //     })
    //     setCategories(data.data)
    //     // setLoading(false)
    //   }
      const updateProduct = async () => {
      setLoading(true)
            const dataTosend = {
              barcode_symbol:data.attributes.barcode_symbol,
              images: filess ? filess : [],
              name:productupdate.name,
              code: productupdate.code,
              product_category_id: productupdate.product_category_id,
              product_price: productupdate.product_price,
              notes: productupdate.notes,
              status: productupdate.status,
              price_purchase: productupdate.price_purchase,
              nbrpoints:productupdate.nbrpoints
            }
    
            const formData = createFormData(dataTosend,image)   
            console.log(dataTosend);
             try {
              const dat = await callApi({
                route: `api/products/`+data.id,
                method: 'POST',
                body: formData,
              })
               toast.success("modifié avec sces")
               loadProduct()
               getProducts()
               modalVisible.current?.click()
               setLoading(false)

             } catch (error:any) {
              toast.error(error.response.data.message)
              setLoading(false)
             }
            
        
            

            // setLoading(false)
            // Toast.success('modifié')
         
        
           
      }
  
   
  return (
    <>
 
      <div
        className={`modal fade `}
        id={'modalupdateproduct' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
        // aria-hidden={modalVisible}
        
      >
        <div className='modal-dialog' role='document' >
          <div className='modal-content'>
            <div className='modal-header ' style={{background:"#1e1e2d"}}>
              <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                Modification de produit: {data.attributes.name}
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' style={{height:"392px",overflowY:"scroll"}}>
              <form>

              <label htmlFor='exampleInputEmail1' className='fw-bold'>
              Photo :
            </label>
            <div className='form-group mb-5  '>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)',
                }}
              >
                <div className='d-flex'>
                  {image.length == 0 ? (
                    <div
                      id='image'
                      className='image-input-wrapper w-125px h-125px mr-2 '
                      style={{
                        backgroundImage:
                          "url('" + data.attributes.images.imageUrls?.[0] + "')",
                      }}
                    ></div>
                  ) : (
                    image.map((file:any) => {
                      return (
                        <div
                          id='image'
                          className='image-input-wrapper w-125px h-125px'
                          style={{
                            backgroundImage: 'url(' + URL.createObjectURL(file) + ')',
                          }}
                        ></div>
                      )
                    })
                  )}
                </div>

                <label
                  className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Change avatar'
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files && e.target.files[0]) {
                        setFiles(URL.createObjectURL(e.target.files[0]));
                        setImage(Array.from(e.target.files));
                        
                      }
                    }}
                    type='file'
                    name='avatar'
                    accept='.png, .jpg, .jpeg'
                    multiple
                  />

                  <input type='hidden' name='avatar_remove' />
                </label>

                <span
                  className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='cancel'
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Cancel avatar'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              </div>
            </div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Nom :
                      </label>
                      <input
                        type='text'
                        value={productupdate?.name}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e:any)=>setProductUpdate({...productupdate,name:e.target.value})}
                              />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Catégorie :
                      </label>
                      <div className='row'>
                        <div className='col-10'>
                          <select 
                           onChange={(e:any)=>setProductUpdate({...productupdate,product_category_id:e.target.value})}
                          className='form-select' aria-label='Default select example'>
                            {
                                categories.map((categorie:any,index:number)=>{
                                        return                             <option key={index} value={categorie.id} selected={data.attributes.product_category_id==categorie.id?true:false}>{categorie.attributes.name}</option>

                                })
                            }
                           
                          </select>
                        </div>
                        <div className='col-2'>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary rounded-5 btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen035.svg'
                              className='svg-icon-2'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col'>
                    {' '}
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Prix de vente :
                      </label>
                      <input
                      value={productupdate?.product_price}
                        type='number'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e:any)=>setProductUpdate({...productupdate,product_price:e.target.value})}

                        
                      />
                    </div>
                  </div>
                  <div className='col'>
                    {' '}
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Prix d'achat :
                      </label>
                      <input
                      value={productupdate?.price_purchase}
                        type='number'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e:any)=>setProductUpdate({...productupdate,price_purchase:e.target.value})}

                      />
                    </div>
                  </div>
                  <div className="col">
                  <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Nombre des points :
                      </label>
                      <input
                      value={productupdate?.nbrpoints}
                        type='number'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e:any)=>setProductUpdate({...productupdate,nbrpoints:e.target.value})}

                      />
                    </div>  
                  </div>
                </div>
                <div className='row  '>
                  <div className="col">
                      <div className='form-group mb-4 fw-bold'>
                    <label htmlFor='exampleInputEmail1'> Code bare :</label>
                    <input
                    value={productupdate?.code}
                      type='text'
                      className='form-control'
                      id='exampleInputEmail1'
                      aria-describedby='text'
                      onChange={(e:any)=>setProductUpdate({...productupdate,code:e.target.value})}

                    />
                  </div>
                 
                  </div>
                  <div className="col">
                  <div className='form-group mb-4 fw-bold'>
                    <label htmlFor='exampleInputEmail1'>Description :</label>
                    <textarea
                    value={productupdate?.notes}
                      rows={2}
                      className='form-control'
                      id='exampleInputEmail1'
                      aria-describedby='text'
                      onChange={(e:any)=>setProductUpdate({...productupdate,notes:e.target.value})}

                    />
                  </div>
                  </div>


                </div>
                <div className="row">
              
                  <div className="col">
                  <div className='form-group d-flex fw-bold'>
                    {' '}
                    <label htmlFor='exampleInputEmail1'>Statut :</label>
                    <div className='form-check form-switch'>
                    <input
  className='form-check-input'
  type='checkbox'
  role='switch'
  onChange={(e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setchejed(checked)
    console.log(checked);
    
    setProductUpdate({ ...productupdate, status: checked ? 1 : 0});
  }}
  checked={chejed}
  id='flexSwitchCheckDefault'
/>


                    </div>
                  </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' ref={modalVisible} data-bs-dismiss='modal'>
                Annuler
              </button>
              {
                loading?    
                <button type='button' className='btn btn-primary'>
                  <Loading/>
               </button>
 :   
  <button type='button'  onClick={updateProduct}className='btn btn-primary'>
         valider
       </button>
              }
          
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
