import React, { useEffect, useState } from 'react';
import ComboBox from '../ComboBox';
import { useCallApi } from '../../modules/utils';

type Props = {
  data: any;
};

export const ProductItemPurchase: React.FC<Props> = ({ data }) => {
  const [products, setProducts] = useState<any[]>([]);

  const callApi = useCallApi();

 

  const getName = (id: number) => {
    
    const product = products.find((product) => product.id == id);
    return product?.attributes?.name;
  };




  return (
    <div>
      <ul id="orderitems" className="list-group mt-2">
        <li className="list-group-item active ">
          <div className="row">
            <div className="col-8">Produit</div>
            <div className="col-2 text-center">Quantitè</div>
            <div className="col-2 text-right">Prix</div>
          </div>
        </li>
        {data.attributes.purchase_items.data.map((purchase_item: any, index: number) => {
          return (
            <li key={index} id="288" className="list-group-item">
              <div className="row">
                <div id="order-item-name" className="col-8">
                  { purchase_item.attributes.product_name}
                  {/* <ComboBox products={products} idProduct={purchase_item.product_id} /> */}
                </div>
                <div className="col-2 d-flex flex-row justify-content-center">
                  <span className="mx-3" id="order-item-quantity">
                    {purchase_item.attributes.quantity}
                  </span>
                </div>
                <div className="col-2 text-right">
                  <span id="order-item-price">{purchase_item.attributes.purchase_price} DH</span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
