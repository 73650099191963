import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {KTSVG} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import SearchComponent from '../SearchComponent'
import {ModalAddCharges} from './ModalAddCharge'
import {ItemsCharge} from './itemsCharge'
import {Link} from 'react-router-dom'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListCharge: React.FC<Props> = ({states}) => {
  const [charges, setCharges] = useState<any[]>([])
  console.log(charges)

  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterProduct, setFilterProduct] = useState<any>([])
  const [typeCharge, setTypeCharge] = useState<any>([])
  const [type, setType] = useState("")
  const callApi = useCallApi()
  const getProducts = async () => {
    try {
      setLoading(true)
      const {data} = await callApi({
        route: 'api/expenses?filter[expense_category_id]='+type+'&page[size]=10&page[number]=' + states.currentPage,
        method: 'GET',
      })

      const initialProducts = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setCharges(initialProducts)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.error('Error fetching product data:', error)
    }
  }

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedProducts = filterProduct.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedProducts.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
      console.log(checkedProductIds)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedProducts
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckedProductIds(checkedIds)
    setFilterProduct(updatedProducts)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedProducts = filterProduct.map((product: any) =>
        product.id === productId ? {...product, checked} : product
      )
      setFilterProduct(updatedProducts)
      const checkedIds = updatedProducts
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
      setCheckedProductIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
    if (window.confirm('Voulez-vous vraiment supprimer cette charge !')) {
       ids.map(async (id: number) => {
   
        const filteredposts = charges.filter((product: any) => product.id !== id)
        setFilterProduct(filteredposts)
        toast.success('Charge   supprimé')
        const {data} = await callApi({
          route: 'api/expenses/' + id,
          method: 'DELETE',
        })  })
      }
  
  }
  const gettypeCharge = async () => {
    try {
      const data = await callApi({
        route: 'api/expense-categories',
        method: 'GET',
      })
      setTypeCharge(data.data.data)
      console.log(data.data.data);
      
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }
  const getPurchaseByName = () => {
    if (searchTerm !== '') {
      const filtered = charges.filter((product) =>
        product.attributes.expense_category_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilterProduct(filtered)
    } else {
      setFilterProduct(charges)
    }
  }

  useEffect(() => {
    getProducts()
    gettypeCharge()
    setFilterProduct(charges)
  }, [states.currentPage, states.totalItems ])

  useEffect(() => {
    getProducts()
    
  }, [type ])
  useEffect(() => {
    getPurchaseByName()
  }, [searchTerm])
  return (
    <>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Liste des Charges
              <SearchComponent
                placeholder={'Recherche Charge'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
           
          >
            <>
              {/* begin::Filter Button */}
              <button
                // disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                Filtre
              </button>
              {/* <Link
                // disabled={isLoading}
                to='/detailes-charge'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <KTSVG path='/media/icons/duotune/graphs/gra010.svg' className='svg-icon-2' />
                Stat
              </Link> */}
              {/* end::Filter Button */}
              {/* begin::SubMenu */}
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
              >
                {/* begin::Header */}
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Options de Filtre </div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  {/* begin::Input group */}
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>Type de charge:</label>
                    <select
                      className='form-select form-select-solid fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={(e)=>setType(e.target.value)}
                    >
                      <option value="">Tous</option>
                      {
                        typeCharge.map((type:any)=>{
                            return <option value={type.id}>{type.attributes.name}</option>
                        })
                      }
                    </select>
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}

                  {/* end::Input group */}

                  {/* begin::Actions 
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      // disabled={isLoading}
                      // onClick={filterData}
                      className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                    >
                      Reset
                    </button>
                    <button
                      // disabled={isLoading}
                      type='button'
                      // onClick={resetData}
                      className='btn btn-primary fw-bold px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                    >
                      Apply
                    </button>
                  </div>
                  {/* end::Actions */}
                </div>
                {/* end::Content */}
              </div>
              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selectionnées
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteProduct(checkedProductIds)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                {' '}
                <button
                  type='button'
                  className='btn btn-sm btn-primary '
                  data-bs-toggle='modal'
                  data-bs-target='#ModalAddUtilisateur'
                >
                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                  Nouvelle charge
                </button>
                <ModalAddCharges />
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterProduct.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-150px '>Charge</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px '>Date</th>
                    <th className='min-w-150px text-center'>Montant TTC</th>
                    <th className='min-w-150px text-center'>Payment</th>

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterProduct.map((product: any, index: any) => {
                    return (
                      <ItemsCharge
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
