import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { CategoriesProvider } from './context/CategoriesContext'
import { ToastContainer } from 'react-toastify'
const App = () => {

  

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
        <CategoriesProvider>
              <AuthInit>
                <ToastContainer />
                <Outlet />
                <MasterInit />
              </AuthInit>
            </CategoriesProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
