import React, { useState } from 'react'


type Props = {
  data: any

  // deleteVille:(id:number)=>void
}
export const ModalProductVendus: React.FC<Props> = ({ data }) => {

  return (

    <div className="modal fade" id={"modalproductvendus"} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable" >
        <div className="modal-content">
          <div className="modal-header ">
            <h1 className="modal-title fs-5 " id="exampleModalLabel">Liste Produits vendus </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body ">

            <div>
              <ul id="orderitems" className="list-group mt-2">
                <li className="list-group-item active ">
                  <div className="row">
                    <div className="col-3">Code</div>
                    <div className="col-5 text-center">Product</div>
                    <div className="col-2 text-right">Quantité Vendue</div>
                    <div className="col-2 text-right"> Total</div>

                  </div>
                </li>
                {data && data.map((purchase_item: any, index: number) => {
                  return (
                    <li key={index} id="288" className="list-group-item">
                      <div className="row">
                        <div id="order-item-name" className="col-3">
                          {purchase_item.reference_code}
                          {/* <ComboBox products={products} idProduct={purchase_item.product_id} /> */}
                        </div>
                        <div className="col-5 d-flex flex-row justify-content-center">
                          <span className="mx-3" id="order-item-quantity">
                            {purchase_item.name}
                          </span>
                        </div>
                        <div className="col-2 text-right">
                          <span id="order-item-price">{purchase_item.total_quantity}</span>
                        </div>
                        <div className="col-2 text-right">
                          <span id="order-item-price">{purchase_item.grand_total} DH</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>


          </div>

        </div>
        <div className="modal-footer">
        </div>
      </div>
    </div>
  )
}
